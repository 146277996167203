<template>
  <div class="row">

    <div class="col col-xl-7 px-0 px-lg-3">
      <div class="card shadow">
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="card-title">
              {{ cityName }}
            </div>
            <button @click="handleSubmitForm" class="btn btn-success"
                    :disabled="$wait.waiting('createCity')">
              <span v-if="!$wait.waiting('createCity')">
                <i class="icons8-save"></i>Сохранить
              </span>
              <BeatLoader v-else
                          :loading="$wait.waiting('createCity')"
                          :color="'#ffffff'"
                          :size="'20px'">
              </BeatLoader>
            </button>
          </div>

          <form id="city-add-form" autocomplete="off"
                @submit.stop.prevent="handleSubmitForm">

            <fieldset :disabled="$wait.waiting('createCity')">

              <div class="form-group row" :class="{'has-error': errors.has('region')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Регион</label>
                <div class="col">
                  <multiselect v-model="regionSelected"
                               :options="regions"
                               :loading="$wait.waiting('asyncFindRegion')"
                               :internal-search="false"
                               :clear-on-select="true"
                               :close-on-select="true"
                               :selectLabel="'Нажмите для выбора'"
                               :selectedLabel="'Выбрано'"
                               :deselectLabel="'Нажмите, чтобы удалить'"
                               :tagPlaceholder="'Введите регион'"
                               label="name"
                               track-by="name"
                               @search-change="asyncFindRegion"
                               placeholder="Начните писать регион...">
                    <div slot="noOptions">Список пуст</div>
                    <span slot="noResult">Не найден ни один регион с таким названием</span>
                  </multiselect>
                </div>
                <div v-show="errors.has('region')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('region') }}
                </div>
              </div>

              <div class="form-group row" :class="{'has-error': errors.has('name')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Город</label>
                <div class="col">
                  <input v-model.trim="cityName"
                         v-validate="'required|max:191'"
                         data-vv-as="Город"
                         name="name"
                         type="text"
                         class="form-control">
                </div>
                <div v-show="errors.has('name')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('name') }}
                </div>
              </div>

            </fieldset>

          </form>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import debounce from 'lodash/debounce'
  import { nullification } from '../../utils/batch'

  export default {
    name: 'CityAdd',

    metaInfo() {
      return {
        title: this.title,
      }
    },

    data() {
      return {
        title: 'Добавить город',
        regions: [],
        regionSelected: {},
        cityName: '',
      }
    },

    methods: {
      handleSubmitForm() {
        this.$validator.validateAll().then( validated => {
          if ( validated ) {
            let formData = nullification( {
              region_id: this.regionSelected ? this.regionSelected.id : null,
              name: this.cityName,
            } )

            this.$wait.start( 'createCity' )

            this.$api.city.createCity( formData ).then( res => {
              if ( res.status === 201 && res.data.city.data ) {
                this.$toasted.show( res.data.message, {
                  type: 'success',
                  action: {
                    text: 'Изменить',
                    onClick: ( e, toastObject ) => {
                      let cityId = res.data.city.data.id
                      this.$router.push( `/cities/${cityId}/edit` )
                    },
                  },
                } )
              }
            } ).finally( () => {
              this.$wait.end( 'createCity' )
            } )
          }
        } )
      },

      asyncFindRegion:
        debounce( function ( query ) {
          this.$wait.start( 'asyncFindRegion' )

          if ( query.length > 1 ) {
            this.$api.region.findRegionByName( query ).then( res => {
              this.regions = res.data.data
            } ).finally( () => {
              this.$wait.end( 'asyncFindRegion' )
            } )
          } else {
            this.$wait.end( 'asyncFindRegion' )
          }
        }, 200 ),
    },
  }
</script>
